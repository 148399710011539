import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { TokenEncryptionService } from 'src/app/CallServices/token-encryption.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private tokenEncryptDecrypt: TokenEncryptionService, private cookieService: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authToken = this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")?.trim());

    // If token is still empty, try getting it from session storage
    authToken = authToken || sessionStorage.getItem("AID")?.trim();

    if (authToken) {
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
      return next.handle(authReq);
    }

    // Proceed with request even if no token is found
    return next.handle(req);
  }
}
